.pagination {
  display: flex;
  position: sticky;
  bottom: 0;
  background: white;
  padding-top: 8px;
  padding-bottom: 6px;
  color: #1f2e39;
  cursor: pointer;
  justify-content: center;
}

.pagination div {
  color: #000;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

@media (max-width: 823px) {
  .pagination div {
    padding: 0 8px;
  }
}

.pagination div.active {
  background-color: #21d6aa;
  color: white;
  border: 1px solid #21d6aa;
  border-radius: 5px;
}

.pagination div:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

/*media query of pagination*/

.pagination-mainstick {
  position: sticky;
  bottom: 0;
  text-align: center;
}

.pagination-mobile {
  display: flex;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #1f2e39;
  cursor: pointer;
  justify-content: center;
}

.pagination-mobile a {
  color: #000;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.pagination-mobile a.active {
  background-color: #21d6aa;
  color: white;
  border: 1px solid #21d6aa;
  border-radius: 5px;
}

.pagination-mobile a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.pagination select.active {
  background-color: #21d6aa;
  color: white;
  border: 1px solid #21d6aa;
  border-radius: 5px;
}
